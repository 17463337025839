<template>
  <div class="results">
    <div class="result_wrapper">
      <div class="result_container">
        <div class="d-flex justify-content-between">
          <div class="result_details">
            <span>{{ resultsData.prize }} {{ currency }}</span>
            <span class="picks">
              {{ $t("pick") }} {{ resultsData.number_of_games }} - 1x2
            </span>
            <span>
              {{ isOngoing ? $t("ongoing") : $t("ended") }}
            </span>
          </div>
          <span class="date">{{ formattedDate(resultsData.start) }} </span>
        </div>
      </div>
    </div>
    <ResultBox
      @addOdd="addOdd"
      :closeAll="activeOdd"
      @openStake="openStake"
      :key="game.id"
      v-for="(game, index) in fixture"
      :gameData="game"
      :index="index"
    />
  </div>
</template>

<script>
// import jpserve from "@/services/jpserve";
import ResultBox from "./ResultBox.vue";

export default {
  name: "JackpotResults",
  props: {
    closeSlideup: {
      required: true,
    },
    fixture: {
      required: true,
      default: [],
    },
    resultsData: {
      type: Object,
      default() {
        return {
          fiatCurrency: process.env.VUE_APP_CURRENCY,
          id: "",
          amount: "",
          currency: "",
          numberofGames: "",
          marketType: "",
          ticketPrice: "",
          taxValue: "",
          date: "",
          time: "",
        };
      },
    },
  },
  components: {
    ResultBox,
    // JackpotResults,
    // ChopbetSlideUp,
  },
  data() {
    return {
      currency: process.env.VUE_APP_CURRENCY,
      isVisible: false,
      activeTab: 0,
      activeOdd: 0,
      slide: "",
      stake: false,
      matchResults: [
        {
          id: 1,
          homeTeam: "Brighton",
          awayTeam: "Manchester United",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
        {
          id: 2,
          homeTeam: "Chelsea",
          awayTeam: "Watford",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
        {
          id: 3,
          homeTeam: "Arsenal",
          awayTeam: "Spurs",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
        {
          id: 4,
          homeTeam: "West Ham",
          awayTeam: "Everton",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
        {
          id: 5,
          homeTeam: "Liverpool",
          awayTeam: "Crystal Palace",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
        {
          id: 6,
          homeTeam: "Man City",
          awayTeam: "Newcastle",
          homeGoals: "2",
          awayGoals: "1",
          time: "1:30 pm",
          date: "Sat 24/09",
          league: "Premier League",
        },
      ],
    };
  },
  methods: {
    closeSlideUp() {
      this.isVisible = false;
      this.$emit("closeSlideup", false);
      document.body.style.overflow = "";
    },
    openResultsSlideUp(slideValue) {
      this.slide = slideValue;
    },
    openStake(stake, odd) {
      this.stake = stake;
      this.activeOdd = odd;
    },
    deleteAll(stake, odd) {
      this.stake = stake;
      this.activeOdd = odd;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(date);
    },
  },
  mounted() {
    console.log(this.resultsData, "resultsData");
    const category_id = this.$route.params.id;
    this.categoryID = category_id;
  },
  computed: {
    isOngoing() {
      const startTime = new Date(this.resultsData.start);
      const endTime = new Date(this.resultsData.end);
      console.log(startTime, endTime);
      return endTime > startTime;
    },
  },
};
</script>

<style scoped src="./styles/jackpotResults.css"></style>
