<template>
  <div class="match-card">
    <div class="match-info">
      <span>
        <span style="margin-right: 12px"> {{ index + 1 }}</span
        >{{ gameData.tournament }}</span
      >
      <span>{{ formatDateTime(gameData.date) }}</span>
    </div>
    <div class="stake_details">
      <div class="match-teams">
        <span>{{ picks.name.split("vs")[0] }}</span> <br />
        <span>{{ picks.name.split("vs.")[1] }}</span>
      </div>
      <div class="odds">
        <div
          v-for="(pick, index) in picks.outcomes"
          :key="index + pick.odds"
          @click="addPick(picks, pick.outcome_id)"
          :class="isOddActive(picks, pick.outcome_id) ? 'active_odd' : 'odd'"
        >
          <span>{{ getTeamClass(pick.outcome_id) }}</span>
          <div>{{ parseFloat(pick.odds).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="stats">
      <div class="d-flex align-items-center" @click="openSlideUp()">
        <StatsIcon />
        <span class="ml-1">{{ this.$t("stats") }}</span>
      </div>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <span class="close" @click="closeSlideUp()"><CancelIcon /></span>
      <JackpotPredictions :statData="gameData" />
    </ChopbetSlideUp>
  </div>
</template>

<script>
import StatsIcon from "../../../components/icons/StatsIcon.vue";
import JackpotPredictions from "./JackpotPredictions.vue";
import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";
import { formatDateTime } from "../../../utils/formatter";
import CancelIcon from "../../../components/icons/CancelIcon.vue";

export default {
  name: "GameBox",
  components: {
    StatsIcon,
    ChopbetSlideUp,
    JackpotPredictions,
    CancelIcon,
    // PlaceBet,
  },
  props: {
    gameData: {
      type: Object,
      required: true,
    },
    closeAll: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeOdd: null,
      selectedOdds: {},
      isSlideUpOpen: false,
      picks: this.gameData,
    };
  },

  watch: {
    closeAll(newVal) {
      if (newVal === 0) {
        this.activeOdd = 0;
      }
    },
    picks() {
      // console.log(this.picks, "picks");
      // this.picks = newVal;
    },
    gameData() {
      this.picks = this.gameData;
    },
  },
  methods: {
    getTeamClass(alias) {
      if (alias === "1") return "1";
      if (alias === "2") return "X";
      if (alias === "3") return "2";
      return "";
    },
    formatDateTime,
    toggleOdd(oddIndex, oddValue) {
      if (this.activeOdd !== null) {
        if (this.activeOdd === oddIndex) {
          this.activeOdd = null;
          this.removePick();
          this.$nextTick(() => {
            this.$root.autoRefreshUI("removePick");
          });
        } else {
          this.activeOdd = oddIndex;
          this.addJackpotPick(oddIndex, oddValue);
          this.$nextTick(() => {
            this.$root.autoRefreshUI("addJackpotPick");
          });
        }
      } else {
        this.activeOdd = oddIndex;
        this.addJackpotPick(oddIndex, oddValue);
        this.$nextTick(() => {
          this.$root.autoRefreshUI("addJackpotPick");
        });
      }
    },
    addPick(pick, outcomeId) {
      const copyPicks = JSON.parse(JSON.stringify(this.picks));
      if (copyPicks.your_pick === outcomeId) {
        copyPicks.your_pick = null;
      } else {
        copyPicks.your_pick = outcomeId;
      }
      this.picks = copyPicks;
      this.$emit("addOdd", copyPicks, outcomeId);
    },
    addJackpotPick(oddIndex, oddValue) {
      const pick = {
        sport_id: this.gameData.sportId,
        match_id: this.gameData.matchId,
        market_name: this.getMarketName(oddIndex),
        market_id: this.getMarketId(oddIndex),
        home_team: this.gameData.homeTeam,
        away_team: this.gameData.awayTeam,
        producer_id: this.gameData.producerId,
        outcome_id: oddIndex,
        odd: oddValue,
        live: this.gameData.live,
      };

      console.log("Adding jackpot pick:", pick);
      this.$root.addJackpotPick(
        pick.sport_id,
        pick.match_id,
        pick.market_name,
        pick.market_id,
        pick.home_team,
        pick.away_team,
        pick
      );

      this.selectedOdds = pick;
      console.log("Jackpot Pick added:", this.selectedOdds);
    },
    removePick() {
      // console.log("Removing pick:", this.selectedOdds);
      this.selectedOdds = {};
      this.$root.autoRefreshUI("removePick");
    },

    isOddActive(pick, outcome_id) {
      return pick.your_pick === outcome_id;
    },

    // Helper function to get market name (update logic based on your requirement)
    getMarketName(oddIndex) {
      if (oddIndex === 1) return "Home Win";
      if (oddIndex === 2) return "Draw";
      if (oddIndex === 3) return "Away Win";
      return "Unknown";
    },

    // Helper function to get market id (update logic based on your requirement)
    getMarketId(oddIndex) {
      return oddIndex; // Update this as needed based on your market logic
    },

    openSlideUp() {
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    // setActiveOdd(odd, stake, gameData) {
    //   if (this.activeOdd == odd) {
    //     this.activeOdd = 0;
    //   } else if (this.activeOdd == 0) {
    //     this.activeOdd = odd;
    //   } else if (this.activeOdd != odd) {
    //     this.activeOdd = odd;
    //   }
    //   this.$emit("openStake", stake);
    //   this.$emit("addOdd", gameData);
    // },
  },
};
</script>

<style scoped>
.match-card {
  display: flex;
  background-color: var(--background-color);
  color: var(--text-color);
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
  border-bottom: 1px solid var(--border);
}

.match-info {
  display: flex;
  justify-content: space-between;
}

.match-info span {
  font-size: 12px;
  /* color: #545151; */
  color: var(--text-color);
}

.stake_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 30px; */
}

.match-teams {
  font-size: 12px;
  font-weight: 500;
  /* color: #0e0d0d; */
  color: var(--text-color);
  /* display: flex; */
  /* flex-direction: column; */
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  line-height: normal;
  letter-spacing: -0.12px;
  width: 35%;
  /* outline: 1px solid red; */
}

.odds {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;
  max-width: 160px;
}

.odd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid var(--border);
  border-radius: 4px;
  /* background: #f5f5f5; */
  background-color: var(--background-color);
  border-radius: 3px;
  color: var(--text-color);
  /* padding: 3px 8px; */
  /* color: #0e0d0d; */
  /* height: 40px; */
  /* text-align: center; */
  cursor: pointer;
  width: 60px;
  /* outline: 1px solid red; */
}

.active_odd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid var(--odd-active);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 8px; */
  /* color: #0e0d0d; */
  border-radius: 4px;
  /* background: #140202; */
  background-color: var(--odd-active);
  color: #ffff;
  width: 60px;
}

.odd span:nth-child(1) {
  color: var(--base-500);
}

.odd span {
  font-size: 10px;
}

.odd div {
  font-size: 12px;
  font-weight: 500;
}

.active_odd span {
  font-size: 10px;
}

.active_odd div {
  font-size: 12px;
  font-weight: 500;
}

.stats {
  display: flex;
  align-items: center;
  color: var(--text-color);
  justify-content: end;
  gap: 4px;
}

.stats span {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
</style>
